var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SButtonCreateOrder',{staticClass:"mb-3",attrs:{"to":"shipment/new"}}),_c('TTableAdvance',{attrs:{"items":_vm.shipment,"fields":_vm.fields,"store":"order.order_shipment","resource":"/sale/orders/shipment","enterable":"","deletable":"","reloadable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"trackings",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.trackings.length > 0)?_c('SMessageTracking',{attrs:{"value":item.trackings[0].code,"checked":item.trackings[0].received}}):_c('TMessageNotFound')],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"id":item.status_id}})],1)]}},{key:"customer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.customer)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink('user', item.customer_id),"content":item.customer.name}}):_vm._e()],1)]}},{key:"shipment_method_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageShipmentMethod',{attrs:{"id":item.shipment_method_id}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.updated_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Order Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"customer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"trackings-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['by_tracking_code'],"placeholder":"Tracking Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'by_tracking_code', $event)},_vm.filterChange]}})]},proxy:true},{key:"shipment_method_id-filter",fn:function(){return [_c('SSelectShipmentMethod',{attrs:{"value":_vm.filter.shipment_method_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "shipment_method_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{staticClass:"text-truncate-0",attrs:{"store":"order.shipment_statuses","value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"updated_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }